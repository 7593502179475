/* STYLES FOR FOOTER */
.footer-section {
    background-color: black;
    color: rgba(255, 255, 255, 0.7);
    padding: 52px 96px 24px 96px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
  }
  
  .footer-links-group {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
  }

  .footer-logo {
    height: 40px;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 200px;
  }
  
  .footer-column h6 {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: rgba(255, 255, 255, 1);
  }
  
  .footer-column a {
    font-weight: 400 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .footer-contact {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .footer-demo-button {
    border: 1px solid white !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: white !important;
    width: fit-content;
  }
  
  .footer-trial-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px 12px 20px !important;
    width: fit-content;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    gap: 12px !important;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    margin-top: 20px;
  }
  
  .footer-bottom-left {
    text-decoration: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: rgba(255, 255, 255, 0.8) !important;
  }
  
  .footer-rights {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(255, 255, 255, 0.6) !important;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-section {
      padding: 40px 20px;
    }
  
    .footer-content {
      text-align: center;
    }

    .footer-contact {
      justify-content: center;
      width: 100%;
    }

    .footer-column {
      width: 100%;
      align-items: center;
    }

    .footer-links-group {
      margin-top: 0;
  }

    .footer-demo-button, .footer-trial-button {
      width: 100%;
    }
  
    .footer-bottom {
      flex-direction: column;
      justify-content: center !important;
      gap: 12px;
    }
  }