/* STYLES FOR HERO SECTION */
.copilot-hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 10%),
    url("../../assets/img/planet_2.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.copilot-hero-left {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 20px;
  /* padding: 0 0 0 96px !important; */
}

.copilot-hero-section h1 {
  text-align: center;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 67px !important;
  color: white !important;
}
.copilot-hero-section h2 {
  text-align: center;
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 67px !important;
  color: white !important;
}
.copilot-hero-section h6 {
  text-align: center;
  color: rgba(255, 255, 255, 0.72) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.copilot-hero-section button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px !important;
  width: fit-content;
}

.copilot-hero-section img {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border: 8px solid #FFFFFF33;
  border-radius: 16px;
}

@media (max-width: 768px) {
  .copilot-hero-section {
      height: 100vh;
      padding: 16px !important;
  }

  .copilot-hero-section h1 {
      font-size: 40px !important;
      line-height: 112% !important;
  }

  .copilot-hero-section h2 {
    font-size: 40px !important;
    line-height: 112% !important;
  }

  .copilot-hero-section a {
      width: 100% !important;
  }

  .copilot-hero-section button {
      width: 100% !important;
      margin-top: 0 !important;
  }
}

/* STYLES FOR ADVANTAGES SECTION */
.advantages-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(to bottom, rgba(12, 11, 15, 1) 0%, rgba(9, 13, 29, 1) 100%);
  background-size: 100% 70%;
  background-repeat: no-repeat;
  /* background-color: rgba(235, 237, 244, 1); */
  padding: 90px 96px !important;
  gap: 20px !important;
}

.advantages-container h3 {
  color: white !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.advantages-container h6 {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.advantages-card {
  display: flex;
  justify-content: center;
}

.advantages-card-content {
  display: flex;
  flex-direction: column;
  flex-direction: column !important;
  gap: 16px !important;
  background: white;
  border-radius: 16px !important;
  padding: 44px;
  text-align: left;
  width: 100%;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
}

.advantages-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
}

.advantages-icon {
  height: 20px;
  width: 20px;
}

.advantages-title {
  font-size: 24px !important;
  font-weight: 700 !important;
}

.advantages-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6B737E;
}

@media (max-width: 768px) {
  .advantages-container {
    padding: 10px 16px !important;
  }
}

/* STYLES FOR BENEFIT SECTION */
.copilot-benefit-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: rgba(235, 237, 244, 1);
  padding: 90px 96px !important;
  gap: 20px !important;
}

.copilot-benefit-container h3 {
  color: black !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.copilot-benefit-container h6 {
  color: rgba(107, 115, 126, 1);
  font-size: 20px !important;
  font-weight: 400 !important;
}

.copilot-benefit-card {
  display: flex;
  justify-content: center;
}

.copilot-benefit-card-content {
  display: flex;
  flex-direction: column !important;
  gap: 16px !important;
  background: white;
  border-radius: 16px !important;
  padding: 44px;
  text-align: left;
  width: 100%;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
  transition: transform 0.2s ease-in-out;
}

.copilot-benefit-card-content img {
  height: auto;
  width: auto;
  object-fit: cover;
  border: 10px solid #EBEDF4;
  border-radius: 16px;
}

.copilot-benefit-title {
  color: black !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.copilot-benefit-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6B737E;
}

/* STYLES FOR COPILOT FEATURES SECTION */
.copilot-features-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  gap: 100px;
  padding: 100px 96px;
}

.copilot-features-grid-container {
  /* max-width: 912px !important; */
}

.copilot-features-left {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 20px !important;
}

.copilot-features-left h3 {
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 46px !important;
  letter-spacing: 0 !important;
}

.copilot-features-left h6 {
  color: #6B737E;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.copilot-features-image-container {
  display: flex;
  justify-content: center;
}

.copilot-features-image {
  width: 100%;
  max-width: 600px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  /* border: 10px solid #EBEDF4; */
  border-radius: 16px;
}

.copilot-features-demo-button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
}

.copilot-features-trial-button {
  border: 1px solid #161A2866 !important;
  border-radius: 16px !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  color: #161A2866 !important;
}

@media (max-width: 768px) {
  .copilot-features-container {
    padding: 100px 16px !important;
  }

  .copilot-features-stack-buttons {
    flex-direction: column !important;
    gap: 16px;
    width: 100%;
}

.copilot-features-stack-buttons a {
    margin-left: 0 !important;
}

.copilot-features-demo-button,
.copilot-features-trial-button {
    width: 100% !important;
}
}

/* STYLES FOR GUIDE SECTION */
.guide-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 40%,
      rgba(0, 0, 0, 1) 100%),
    url("../../assets/img/planet.png");
  background-size: 100% 70%;
  background-repeat: no-repeat;
  background-color: white;
  padding: 90px 96px !important;
  gap: 8px !important;
}

.guide-container h3 {
  color: white !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.guide-video {
  width: 100%;
  max-width: 912px;
  border-radius: 16px;
  border: 10px solid rgba(255, 255, 255, 0.5);
  margin-top: 30px;
}
