/* STYLES FOR INTEGRATIONS */
.integrations-section {
  display: flex;
  flex-direction: column;
  padding: 100px 96px;
  background: #ebedf4;
  align-items: center;
  gap: 48px !important;
}

.integrations-section h3 {
  background: #ebedf4;
  text-align: center;
}

.integrations-grid {
  display: flex;
  justify-content: center;
  opacity: 1;
  -webkit-mask-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 60%,
      rgba(255, 255, 255, 0.3) 70%,
      rgba(255, 255, 255, 0.1) 90%,
      rgba(255, 255, 255, 0) 100%);
  mask-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 60%,
      rgba(255, 255, 255, 0.3) 70%,
      rgba(255, 255, 255, 0.1) 90%,
      rgba(255, 255, 255, 0) 100%);
  transition: mask-image 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.integration-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px !important;
  background: white;
  border: 1px solid #E9E9E9;
  box-shadow:
    0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
  transition: transform 0.2s ease-in-out, box-shadow 0.3s;
}

.integration-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px !important;
  padding: 30px 60px !important;
}

.integration-content p {
  font-size: 20px !important;
  font-weight: 700 !important;
  white-space: nowrap;
  text-align: center;
}


.integration-logo {
  height: 30px;
}

.integrations-section button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
}

@media (max-width: 768px) {
  .integrations-section {
    padding: 100px 16px;
  }

  .learn-more-left a {
    width: 100%;
  }

  .integration-card.faded {
    -webkit-mask-image: none !important;
    mask-image: none !important;
    opacity: 1 !important;
    transition: none !important;
  }

  .integration-card {
    height: 160px;
    min-height: 160px;
  }

  .integration-content {
    height: 100%;
    justify-content: center;
    text-align: center;
  }

  .integrations-section button {
    width: 100%
  }
}