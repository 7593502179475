/* STYLES FOR HERO SECTION */
.agents-hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 10%),
        url("../../assets/img/ai_agents_hero_img.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 100vh !important;
}

.agents-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding: 180px 96px 100px 96px !important;
    gap: 14px !important
}

.agents-hero-section h1 {
    font-weight: 700 !important;
    color: white !important;
}

.agents-hero-section h3 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}

button.conversational-button {
    color: white !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    background: linear-gradient(177.95deg, #6257FF 2.52%, #5145D0 97.69%);
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: 170px;
}

button.vertical-button {
    color: white !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    background-color: rgba(255, 255, 255, 0.12) !important;
    backdrop-filter: blur(100px);
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: 170px;
}

.black-overlay {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.5s ease;
}

.black-overlay h2 {
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 140% !important;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    max-width: 1000px;
}

@media (max-width: 768px) {
    .agents-hero-section {
        justify-content: center;
        background-size: cover;
    }

    .agents-hero-content {
        padding: 80px 16px !important;
    }

    .agents-hero-section h1 {
        font-size: 40px !important;
        line-height: 112% !important;
    }

    .agents-hero-section h2 {
        font-size: 28px !important;
        line-height: 140% !important;
        padding: 80px 16px !important;
    }

    .agents-hero-section h3 {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 150% !important;
    }

    .agents-stack-buttons {
        flex-direction: column !important;
        gap: 16px;
        width: 100%;
    }

    .vertical-button,
    .conversational-button {
        width: 100% !important;
    }

    .vertical-button {
        margin-left: 0 !important;
    }
}

/* STYLES FOR CONVERSATIONAL AGENTS SECTION */
.conversational-agents-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 96px;
    background: rgba(236, 238, 245, 1);
}

.conversational-agents-title {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
    text-align: center;
    max-width: 1200px !important;
}

.conversational-agents-subtitle {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-align: center;
    width: 100%;
}

.conversational-agents-grid {
    display: flex !important;
    align-items: center;
    margin-top: 40px !important;
    width: 1200px !important;
}

.conversational-widget-grid {
    display: flex;
    align-items: center !important;
    justify-content: center;
}

.conversational-chat-widget-wrapper {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 500px;
    border-radius: 24px;
    background: white;
    box-shadow: 0px 2.03px 27.9px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 16px;
    position: relative;
}

.conversational-chat-messages-container {
    max-height: 430px;
    overflow-y: auto;
}

.conversational-chat-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.conversational-chat-name {
    font-size: 12px !important;
    color: black !important;
    margin-bottom: 6px !important;
}

.conversational-chat-bubble {
    padding: 11px 13px;
    border-radius: 12px;
    word-break: break-word;
    max-width: 80%;
}

.conversational-chat-bubble p {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
}

.conversational-chat-bubble.white {
    background-color: rgb(245, 248, 253);
    border-top-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    align-self: flex-start;
}

.conversational-chat-bubble.dark {
    background-color: #344054;
    color: white;
    border-top-right-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    align-self: flex-end;

}

.conversational-chat-bubble.dark.small {
    padding: 6px 10px;
    font-size: 12px;
    align-self: flex-end;
}

.conversational-input-bubble {
    margin-top: auto;
    background-color: #1F2430;
    color: white;
    border-radius: 12px;
    padding: 10px 14px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.conversational-chat-input-wrapper {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    border: 1px solid rgba(214, 214, 214, 1);
    border-radius: 16px;
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    color: #fff;
    font-size: 16px;
}

.conversational-chat-input-text {
    color: black;
    font-size: 14px !important;
    flex: 1;
}

.placeholder-text {
    color: rgba(214, 214, 214, 1) !important;
}

.conversational-send-icon {
    color: rgba(98, 87, 255, 1) !important;
    font-weight: bold;
    margin-left: 12px !important;
}

.send-icon-disabled {
    color: grey !important;
}

@media (max-width: 768px) {
    .conversational-agents-section {
        padding: 80px 16px;
        align-items: flex-start;
    }

    .conversational-agents-title {
        line-height: 130% !important;
        text-align: left;
    }

    .conversational-agents-subtitle {
        font-size: 18px !important;
        line-height: 130% !important;
        text-align: left;
    }

    .conversational-widget-grid {
        align-self: center;
    }
}

/* STYLES FOR VERTICAL AGENTS SECTION */
.vertical-agents-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 96px;
    background: rgba(236, 238, 245, 1);
}

.vertical-agents-title {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
    text-align: center;
    max-width: 1200px !important;
}

.vertical-agents-subtitle {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-align: center;
    width: 100%;
}

.vertical-agents-scroll-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
  }

.vertical-agents-grid-container {
    margin-top: 0 !important;
}

.vertical-agent-item {
    margin-bottom: 16px;
}

.vertical-agent-title {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #1F2430;
    border-bottom: 1px solid #D7DBEC;
}

.vertical-agent-title.active {
    font-weight: 700;
    color: linear-gradient(90.65deg, #8D2BFF 0.82%, #382AFF 100%);

    border-bottom: 2px solid #8D2BFF;
}

.vertical-agent-description {
    color: #6B737E;
    margin-bottom: 16px;
}

.vertical-agent-description-wrapper {
    padding-bottom: 10px;
}

.vertical-agent-description {
    color: #6B737E;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    max-width: 90%;
}

.circle-stack {
    position: relative;
    height: 60px;
    margin-bottom: 20px;
}

.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgba(241, 242, 248, 1);
    background-color: #F3F6FB;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-1 {
    left: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
    z-index: 3;
}

.circle-2 {
    left: 50px;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.03);
    z-index: 4;
}

.circle-3 {
    left: 100px;
    box-shadow:
        0px 26px 16px rgba(0, 0, 0, 0.02),
        0px 46px 19px rgba(0, 0, 0, 0),
        0px 72px 20px rgba(0, 0, 0, 0);
    z-index: 5;
}

.circle-4 {
    left: 150px;
    box-shadow:
        0px 26px 16px rgba(0, 0, 0, 0.02),
        0px 46px 19px rgba(0, 0, 0, 0),
        0px 72px 20px rgba(0, 0, 0, 0);
    z-index: 6;
}

.vertical-agent-right-img {
    border-radius: 8px;
    width: 100%;
}

.marketplace-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

@media (max-width: 768px) {
    .vertical-agents-section {
        padding: 80px 16px;
        align-items: flex-start;
    }

    .vertical-agents-title {
        line-height: 130% !important;
        text-align: left;
    }

    .vertical-agents-subtitle {
        font-size: 18px !important;
        line-height: 130% !important;
        text-align: left;
    }

    .vertical-agent-description {
        line-height: 180% !important;
    }
}

/* STYLES FOR CONTROL SECTION */
.control-component {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 96px;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 10%),
        url("../../assets/img/planet_3.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    text-align: center;
    height: 350px;
    gap: 30px;
}

.control-component::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 300px;
    background-image: url("../../assets/img/planet_overlay.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    pointer-events: none;
    z-index: 1;
}

.control-counter {
    color: white !important;
    font-weight: 700 !important;
    font-size: 84px !important;
    line-height: 130% !important;
    letter-spacing: 0px !important;
    text-align: center;
}

.control-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: linear-gradient(180deg, #050506 0%, #0F0F1A 100%);
    padding: 100px 96px;
    height: 100vh !important;
}

.control-main-content-title {
    color: white;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 130% !important;
    letter-spacing: 0 !important;
    text-align: center;
    max-width: 1200px !important;
}

.control-main-content-subtitle {
    color: rgba(255, 255, 255, 0.72);
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    text-align: center;
    width: 100%;
}

.control-main-content-grid-container {
    margin-top: 0 !important;
    display: flex !important;
    align-items: center;
    background-image: url("../../assets/img/sand_clock.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 700px !important;
}

.control-main-content-img-grid-container {
    padding-left: 0 !important;
}

.phases-card {
        display: flex;
        flex-direction: column;
        flex-direction: column !important;
        gap: 16px !important;
        background-color: rgba(37, 29, 47, 0.8) !important;
        border: 1px solid #453953;
        border-radius: 16px !important;
        padding: 44px !important;
        text-align: left;
        width: 100%;
        max-width: 390px;
        box-shadow: 0px 3px 6px 0px #00000008,
          0px 12px 12px 0px #00000008,
          0px 26px 16px 0px #00000005,
          0px 46px 19px 0px #00000000,
          0px 72px 20px 0px #00000000;
}

.control-main-content-img {
    width: 150%;
}

.control-main-content-right-title {
    font-weight: 700 !important;
    font-size: 16px !important;
    color: white !important;
    line-height: 130% !important;
}

.control-main-content-right-grid-container {
    display: flex;
    flex-direction: column !important;
    gap: 16px;
}

@media (max-width: 768px) {
    .control-component {
        padding: 80px 16px;
    }

    .control-counter {
        font-size: 56px !important
    }

    .control-main-content {
        padding: 80px 16px;
    }

    .control-main-content-title {
        text-align: left;
    }

    .control-main-content-subtitle {
        font-size: 18px !important;
        text-align: left;
    }

    .control-main-content-right-title {
        font-weight: 700 !important;
        font-size: 20px !important;
    }
}

/* STYLES FOR HIRE AGENTS SECTION */
.hire-agents-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 96px;
    background: rgba(236, 238, 245, 1);
}

.hire-agents-title {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 130% !important;
    letter-spacing: 0 !important;
    text-align: center;
    max-width: 1200px !important;
}

.hire-agents-subtitle {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    text-align: center;
    width: 100%;
}

.hire-agents-grid-container {
    margin-top: 0 !important;
}

.hire-agents-card-content {
    display: flex;
    gap: 16px !important;
    background: white;
    border-radius: 12px !important;
    padding: 28px;
    text-align: left;
    width: 100%;
    box-shadow: 0px 3px 6px 0px #00000008,
        0px 12px 12px 0px #00000008,
        0px 26px 16px 0px #00000005,
        0px 46px 19px 0px #00000000,
        0px 72px 20px 0px #00000000 !important;
}

.hire-agents-card-title {
    color: black !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.hire-agents-card-description {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #6B737E;
}

.hire-agents-card-content img {
    width: auto;
    max-width: 97%;
    border-radius: 16px;
    margin-top: 16px;
    object-fit: contain;
    height: fit-content;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .hire-agents-section {
        padding: 80px 16px;
        align-items: flex-start;
    }

    .hire-agents-title {
        text-align: left;
    }

    .hire-agents-subtitle {
        font-size: 18px !important;
        text-align: left;
    }

    .hire-agents-card-content {
        flex-direction: column;
        width: auto;
        padding: 20px;
    }
}