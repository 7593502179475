/* STYLES FOR HERO SECTION */
.advanced-analytics-hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 20%),
        url("../../assets/img/planet.png");
    background-size: cover;
    background-position: center 400px;
    background-repeat: no-repeat;
}

.advanced-analytics-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding: 180px 96px 100px 96px !important;
    gap: 14px !important
}

.advanced-analytics-hero-section h1 {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 67px !important;
    color: white !important;
    max-width: 1000px;
}

.advanced-analytics-hero-section h3 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    max-width: 1000px;
}

.advanced-analytics-hero-section button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

.advanced-analytics-hero-section h6 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.advanced-analytics-image {
    width: 100%;
    border-radius: 16px;
    border: 10px solid rgba(255, 255, 255, 0.5);
    margin-top: 30px;
}

/* STYLES FOR FEATURES SECTION */
.advanced-analytics-visibility-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    background: linear-gradient(180deg, rgba(235, 237, 244, 1) 0%, #FFFFFF 100%);
    padding: 100px 96px;
}

.advanced-analytics-visibility-container h3 {
    font-size: 40px !important;
    font-weight: 700 !important;
}

.advanced-analytics-visibility-container h6 {
    color: rgba(107, 115, 126, 1);
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    margin-top: 20px;
    margin-bottom: 72px;
}

.advanced-analytics-visibility-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 96px;
}

.advanced-analytics-visibility-left {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 20px !important;
}

.advanced-analytics-visibility-left h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
}

.advanced-analytics-visibility-left h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.advanced-analytics-visibility-image-container {
    display: flex;
    justify-content: center;
}

.advanced-analytics-visibility-image {
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border: 10px solid #EBEDF4;
    border-radius: 16px;
}

.advanced-analytics-visibility-demo-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px 12px 20px !important;
    width: fit-content;
}

.advanced-analytics-visibility-trial-button {
    border: 1px solid #161A2866 !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: #161A2866 !important;
}
