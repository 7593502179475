/* STYLES FOR PLANET COMPONENT */
.planet-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 97px 96px;
    background-color: rgba(18, 9, 29, 1);
    background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 10%),
    url("../../assets/img/planet_2.png");
    background-size: cover;
    background-position: center 120px;
    background-repeat: no-repeat;
    text-align: center;
    height: 300px;
    gap: 30px;
    padding: 100px 96px;
}

.planet-component h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 50px !important;
    color: white !important;
    z-index: 1000;
}

.planet-component h6 {
    color: rgba(255, 255, 255, 1);
    font-size: 14px !important;
    font-weight: 400 !important;
    z-index: 1000;
}

.planet-component-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

@media (max-width: 768px) {
    .planet-component {
        height: auto;
        gap: 30px;
        padding: 100px 16px;
    }

    .planet-component a {
       width: 100%;
    }


    .planet-component-button {
        width: 100% !important;
    }
}