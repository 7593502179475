/* STYLES FOR WHY CHOOSE INTELSWIFT */
.why-choose-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgba(235, 237, 244, 1);
  padding: 100px 96px;
  gap: 20px;
}

.why-choose-container h3 {
  text-align: center;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
  max-width: 850px;
}

.why-choose-container h6 {
  color: rgba(107, 115, 126, 1);
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  max-width: 850px;
  margin-bottom: 20px !important;
}

.why-choose-card {
  display: flex;
  justify-content: center;
}

.products-grid {
  max-width: 900px !important;
  width: 100%;
}

.why-choose-card-content {
  display: flex;
  flex-direction: column;
  flex-direction: column !important;
  gap: 16px !important;
  background: white;
  border-radius: 16px;
  padding: 44px;
  text-align: left;
  width: 100%;
  max-width: 390px;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000;
}

.css-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #9457FF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.css-circle::after {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
}

.css-circle::before {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
}

.why-choose-title {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
}

.why-choose-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6B737E;
  line-height: 130% !important;
}

.why-choose-stack-buttons {
  gap: 12px;
}

.why-choose-demo-button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
}

.trial-button {
  border: 1px solid #161A2866 !important;
  border-radius: 16px !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  color: #161A2866 !important;
}

@media (max-width: 768px) {
  .why-choose-container {
    padding: 100px 16px;
  }

  .cta-container a {
    width: 100%;
  }

  .why-choose-stack-buttons {
    flex-direction: column !important;
    gap: 16px;
    width: 100%;
  }

  .why-choose-demo-button, .trial-button {
    width: 100% !important;
  }
}