/* STYLES FOR HEADER SECTION */
.header-wrapper {
    background-color: transparent !important;
    box-shadow: none !important;
    position: fixed;
    z-index: 1300 !important;
}

.header-container {
    display: flex;
    justify-content: center;
}

.header-logo {
    height: 36px;
    margin-right: 48px;
}

.burger-menu {
    display: none;
}

.header-middle-section-wrapper {
    display: flex;
    align-items: center;
    background-color: rgba(37, 29, 47, 0.8) !important;
    padding: 12px 16px;
    margin: 12px;
    border-radius: 10px;
    border: 1px solid #453953;
    transition: background-color 0.3s ease, border 0.3s ease;
}

.header-middle-section {
    display: flex;
    align-items: center;
    gap: 24px;
}

.header-middle-section button {
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: none !important;
    padding: 0 !important;
    border-radius: 16px !important;
}

.header-middle-section svg {
    height: 12px !important;
    width: 12px !important;
}

.products-menu-popover {
    margin-top: 15px !important;
    z-index: 1300 !important;
}

.products-menu-container {
    display: flex;
    width: 652px;
    background: #352B41;
    color: white;
    border-radius: 8px;
    padding: 10px;
    gap: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.products-list {
    width: 209px !important;
    padding: 0 !important;
}

.product-item {
    padding: 10px 16px;
    color: rgba(255, 255, 255, 0.7);
}

.product-item span {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.product-item:hover,
.product-item.active {
    background: #453953 !important;
    color: white !important;
}

.product-preview {
    flex: 1;
    padding: 16px;
    background-color: #453953;
    border-radius: 8px;
}

.product-preview .product-image {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-preview:hover .product-image {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
}

.product-title {
    font-weight: bold;
}

.product-description {
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.8) !important;
    font-weight: 400 !important;
}

.solutions-menu-popover {
    margin-top: 15px !important;
    z-index: 1300 !important;
}

.solutions-menu-container {
    display: flex;
    width: 400px;
    background: #352B41;
    color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.solutions-column {
    flex: 1;
}

.solutions-column p {
    margin-left: 16px;
}

.solutions-column hr {
    background-color: #FFFFFF0A !important;
}

.solutions-title {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 8px;
}

.solutions-list {
    padding: 0;
}

.solutions-item span {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.solutions-item:hover {
    background: #453953 !important;
    color: white !important;
}

.resources-menu-popover {
    margin-top: 15px !important;
    z-index: 1300;
}

.resources-menu-container {
    width: 131px;
    background: #352B41;
    color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.resources-menu-container ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.resources-list {
    padding: 0;
}

.resources-item span {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.resources-item:hover {
    background: #453953 !important;
    color: white !important;
}

.header-middle-section a {
    text-decoration: none;
}

.header-middle-section p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 400;
}

.login-button {
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: none !important;
    margin-left: 48px !important;
    border-radius: 16px !important;
    margin-right: 12px !important;
}

.free-trial-button {
    color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    margin-left: 0 !important;
    border: 1px solid rgba(255, 255, 255, 0.8) !important;
    border-radius: 16px !important;
}

.demo-button {
    color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
}

.login-button-drawer {
    align-self: center;
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-transform: none !important;
}

.free-trial-button-drawer {
    color: white !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    margin-left: 0 !important;
    border: 1px solid rgba(255, 255, 255, 0.8) !important;
    border-radius: 16px !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.demo-button-drawer {
    color: white !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.drawer-wrapper {
    background-color: rgba(18, 9, 29, 1);
}

.drawer-list-item-name {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

.drawer-item-name {
    color: white !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

/* Responsive Header for Small Screens */
@media (max-width: 768px) {

    .header-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
        background-color: rgba(18, 9, 29, 1);
    }

    .header-middle-section {
        display: none !important;
    }

    .header-middle-section-wrapper {
        width: 100%;
        background-color: transparent !important;
        border: 1px solid transparent !important;
    }

    .burger-menu {
        display: block !important;
        margin-left: auto;
        cursor: pointer;
        color: white;
    }

    .header-logo {
        margin-right: auto;
    }

    .login-button {
        display: none !important;
    }

    .free-trial-button {
        display: none !important;
    }

    .demo-button {
        display: none !important;
    }
}