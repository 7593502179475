/* STYLES FOR HERO SECTION */
.knowledge-base-hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 10%),
        url("../../assets/img/planet_2.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.knowledge-base-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding: 180px 96px 100px 96px !important;
    gap: 14px !important
}

.knowledge-base-hero-section h1 {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 67px !important;
    color: white !important;
}

.knowledge-base-hero-section h3 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}

.knowledge-base-hero-section button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

.knowledge-base-hero-section h6 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.knowledge-base-image {
    width: 100%;
    border-radius: 16px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .knowledge-base-hero-content {
        height: 100vh;
        padding: 16px !important;
    }

    .knowledge-base-hero-content h1 {
        font-size: 40px !important;
        line-height: 112% !important;
    }

    .knowledge-base-hero-content a {
        width: 100% !important;
    }

    .knowledge-base-hero-content button {
        width: 100% !important;
        margin-top: 0 !important;
    }
}

/* STYLES FOR FEATURES SECTION */
.knowledge-base-features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 96px;
}

.knowledge-base-features-section-title {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
    text-align: center;
    max-width: 1200px !important;
}

.knowledge-base-features-section-subtitle {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-align: center;
    width: 100%;
}

.knowledge-base-features-container {
    display: flex;
    justify-content: center;
    align-items: center !important;
    padding: 100px 0;
}

.knowledge-base-features-left {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 20px !important;
}

.knowledge-base-features-left h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
}

.knowledge-base-features-left h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.knowledge-base-features-image-container {
    display: flex;
    justify-content: center;
}

.knowledge-base-features-image {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}

@media (max-width: 768px) {
    .knowledge-base-features-section {
        padding: 100px 16px 100px 16px !important;
    }
}

/* STYLES FOR DISTRIBUTE CONTENT SECTION */
.distribute-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #FFFFFF 0%, #EBEDF4 100%);
    padding: 100px 96px !important;
    gap: 40px !important;
}

.distribute-content-container h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 1px !important;
}

.distribute-content-card {
    display: flex;
    justify-content: center;
}

.distribute-content-card-content {
    display: flex;
    flex-direction: column;
    flex-direction: column !important;
    gap: 16px !important;
    background: white;
    border-radius: 16px !important;
    padding: 44px;
    text-align: left;
    width: 100%;
    box-shadow: 0px 3px 6px 0px #00000008,
        0px 12px 12px 0px #00000008,
        0px 26px 16px 0px #00000005,
        0px 46px 19px 0px #00000000,
        0px 72px 20px 0px #00000000 !important;
}

.distribute-content-circle {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.distribute-content-icon {
    height: 20px;
    width: 20px;
}

.distribute-content-title {
    font-size: 24px !important;
    font-weight: 700 !important;
}

.distribute-content-description {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #6B737E;
}

.distribute-content-stack h6 {
    color: rgba(15, 42, 67, 1);
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: center;
}

.distribute-content-stack button {
    border: 1px solid rgba(22, 26, 40, 0.4);
    padding: 12px 20px;
    border-radius: 16px;
    color: rgba(38, 42, 58, 1);
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
}

@media (max-width: 768px) {
    .distribute-content-container {
        padding: 100px 16px 100px 16px !important;
    }

    .distribute-content-stack {
        width: 100%;
    }

    .distribute-content-stack button {
        width: 100%;
    }
}

/* STYLES FOR KNOWLEDGE AUTOMATION SECTION */
.knowledge-section {
    background: rgba(235, 237, 244, 1);
    padding: 100px 96px;
}

.knowledge-section h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
}

.knowledge-section h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.knowledge-left-section {
    display: flex;
    flex-direction: column !important;
    gap: 20px;
}

.knowledge-left-section button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

.knowledge-right-section {
    display: flex;
    flex-direction: column !important;
    gap: 12px !important;
}

.knowledge-right-section-title {
    color: rgba(22, 26, 40, 1) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.knowledge-card {
    gap: 16px !important;
    background: white;
    border-radius: 16px !important;
    padding: 44px;
    text-align: left;
    box-shadow: 0px 3px 6px 0px #00000008,
        0px 12px 12px 0px #00000008,
        0px 26px 16px 0px #00000005,
        0px 46px 19px 0px #00000000,
        0px 72px 20px 0px #00000000 !important;
}

.knowledge-card-content {
    display: flex;
    gap: 16px;
}

.knowledge-card-circle {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.knowledge-card-icon {
    height: 20px;
    width: 20px;
}

.knowledge-card-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.knowledge-feature-title {
    font-size: 24px !important;
    font-weight: 700 !important;
}

.knowledge-feature-description {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #6B737E;
}

@media (max-width: 768px) { 
    .knowledge-section {
        padding: 100px 16px;
    } 
}