/* STYLES FOR BLOG */
.blog-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 100px 96px;
  background: rgba(235, 237, 244, 1);
}

.blog-section-live-chat-background {
  background: linear-gradient(180deg, #EBEDF4 0%, #FFFFFF 100%) !important;
}

.blog-section h3 {
  text-align: center;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.blog-section h6 {
  color: #6B737E;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  margin-bottom: 20px !important;
}

.blog-card {
  display: flex;
  flex-direction: column;
  border-radius: 24px !important;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
  cursor: pointer;
  padding: 20px;
  gap: 16px;
}

.blog-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px !important;
}

.blog-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 0 20px 0 !important;
}

.blog-content div {
  width: fit-content;
  padding: 8px;
  color: rgba(77, 104, 129, 1);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
}

.blog-content h6 {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  color: black !important;
  min-height: 85px !important;
}

.blog-content p {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(22, 14, 32, 0.68) !important;
  min-height: 75px !important;
}

.blog-content span {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  color: rgba(107, 114, 128, 1) !important;
}

.blog-type {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: rgba(229, 237, 255, 1);
  border-radius: 10px;
  padding: 0 5px 0 5px;
  margin-bottom: 16px !important;
}

.blog-type-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgba(66, 56, 157, 1) !important;
}

.blog-title {
  font-size: 28px !important;
  font-weight: 500 !important;
  line-height: 31.36px !important;
  letter-spacing: 0.46px !important;
}

.blog-subtitle {
  margin-top: 16px !important;
  font-size: 16px !important;
  font-weight: 200 !important;
  line-height: 24px !important;
  margin-bottom: 16px !important;
}

.blog-date {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgba(107, 114, 128, 1) !important;
}

.blog-page-container {
  padding: 87px 40px 40px 40px;
}

.blog-page-title {
  font-size: 38px !important;
  padding-bottom: 30px;
}

.blog-section button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
}

@media (max-width: 768px) {
  .blog-section {
    padding: 100px 16px;
  }

  .blog-section-title {
    font-size: 44px !important;
    line-height: 49px !important;
    font-weight: 600 !important;
  }

  .blog-section-subtitle {
    font-size: 18px !important;
    line-height: 27px !important;
    font-weight: 400 !important;
    text-align: center;
  }

  .blog-section-card-list {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 20px;
  }

  .blog-section button {
    width: 100%;
  }
}

.breadcrumbs-link {
  color: var(--text-primary) !important;
}

.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  padding: 20px;
}

.sticky-nav-list {
  height: 80vh;
  overflow-y: auto;
}

.blog-post-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E9E7FF !important;
  max-height: 450px;
}

.left-section {
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  flex: 6;
}

.right-section {
  flex: 6;
}

.meta-info {
  display: flex;
  align-items: center;
  color: grey;
  gap: 8px;
}

.blog-image img {
  width: 100%;
  height: auto;
  max-height: 450px;
  object-fit: cover;
  object-position: center top;
  display: block;
  margin: 0;
}

.author-info {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.author-info img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.blog-post-title {
  width: 100%;
  font-size: 40px !important;
  line-height: 44px !important;
  font-weight: 700 !important;
}

.blog-post-subtitle {
  font-size: 28px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
}

.blog-post-layout {
  padding: 60px;
}

.blog-post-container {
  position: relative;
  padding: 30px !important;
  border-radius: 30px;
  margin: 40px 70px 40px 70px !important;
  background-color: white;
}

.blog-post-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: #E9E7FF !important;
}

.media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 40px 0;
}

.media-title {
  color: #2A52BE !important;
  font-weight: 500 !important;
  max-width: 450px;
}

.media-paragraph {
  font-weight: 600 !important;
  padding-bottom: 5px;
  font-size: 18px !important;
  color: black !important;
}

.small-img {
  max-width: 500px;
}

.blog-paragraph h4 {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: black !important;
}

.blog-paragraph img {
  width: 100%;
  padding: 20px;
  padding-left: 196px;
  padding-right: 196px;
  box-sizing: border-box;
}

.ol-list-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
}

.number-span {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  background-color: #2A52BE;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 10px;
}

.ol-container {
  padding: 0 0 40px 0;
}

.url {
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: rgba(22, 14, 32, 0.68) !important;
}

.try-intelswift-button {
  color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background-color: rgba(141, 43, 255, 1) !important;
  padding: 8px 16px 8px 16px !important;
  border-radius: 50px 50px 50px 50px !important;
  width: fit-content;
}

@media (max-width: 899px) {
  .breadcrumbs-container {
    padding: 20px;
  }

  .blog-post-title-container {
    flex-direction: column;
    max-height: none;
  }

  .left-section {
    padding: 20px !important;
    flex: 0 0 100%;
  }

  .right-section {
    flex: 0 0 100%;
  }

  .blog-image img {
    max-height: none;
  }

  .blog-post-layout {
    padding: 48px 12px;
  }

  .blog-post-container {
    margin: 16px 0 16px 0 !important;
  }

  .small-img {
    max-width: 400px;
  }

  .blog-paragraph img {
    padding: 20px 0 20px 0;
  }
}