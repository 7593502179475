/* STYLES FOR PRODUCT */
.product-ecosystem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 96px;
  gap: 40px;
  background-color: #ebedf4;
}

.advanced-analytics-background {
  background: linear-gradient(180deg, #EBEDF4 63.86%, #FFFFFF 100%);
}

.product-ecosystem h3 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1px;
}

.product-ecosystem a {
  color: white !important;
  font-size: 16px !important;
  border-radius: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
}

.product-grid {
  display: flex;
  justify-content: center;
}

.product-card {
  display: flex;
  width: 100%;
}

.product-card-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  padding: 44px;
  border-radius: 16px;
  align-items: flex-start;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
  width: 100%;
}

.product-icon {
  height: 40px;
  width: 40px;
}

.product-title {
  font-size: 24px;
  font-weight: 700;
}

.product-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6B737E;
}

.learn-more {
  font-size: 20px;
  font-weight: 700;
  color: #6257ff;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.learn-more:hover {
  color: #5148E0;
}

@media (max-width: 768px) {
  .product-ecosystem {
    padding: 100px 16px;
  }

  .product-ecosystem a {
    width: 100%;
  }
}