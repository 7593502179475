/* STYLES FOR FAQs */
.faqs-section {
  padding: 100px 96px !important;
  background-color: white !important;
}

.faqs-title {
  font-weight: 700 !important;
  font-size: 36px !important;
}

.faqs-description {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #6B737E !important;

}

.faqs-email {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #6B737E !important;
  text-decoration: none !important;
}

.faqs-accordion {
  box-shadow: none !important;
  border-bottom: 1px solid #E0E0E0;
}

.faqs-accordion::before {
  display: none;
}

.faqs-question {
  font-weight: 700 !important;
  font-size: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.faqs-question svg {
  color: black !important;
}

.faqs-answer {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6B737E !important;
}

@media (max-width: 768px) {
  .faqs-section {
    padding: 100px 16px !important;
  }
}