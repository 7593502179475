/* STYLES FOR TRY FOR LEVEL UP COMPONENT */
.level-up-section {
    padding: 100px 96px;
    background: linear-gradient(180deg, #FFFFFF 0%, #EBEDF4 100%);

}

.live-chat-background {
    background: rgba(235, 237, 244, 1) !important;
}

.workflows-background {
    background: rgba(235, 237, 244, 1);
}

.level-up-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: rgba(22, 14, 32, 1) !important;
    box-shadow: none !important;
    border-radius: 24px !important;
}

.level-up-card-left-content {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    padding: 60px !important;
    gap: 20px !important;
}

.level-up-card-left-content h3 {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 72px !important;
    letter-spacing: 1px !important;
    color: white !important;
}

.level-up-card-left-content h6 {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: white !important;
}

.level-up-card-left-content button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

.level-up-card-right-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
}

.level-up-card-right-content img {
    position: relative;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: -50px !important;
}

@media (max-width: 899px) {
    .level-up-section {
        padding: 100px 16px;
    }

    .level-up-card {
        min-height: 600px;
    }

    .level-up-card-left-content {
        padding: 28px 24px 28px 24px !important;
        z-index: 2;
    }

    .level-up-card-left-content h3 {
        font-size: 36px !important;
        font-weight: 700 !important;
        line-height: 43px !important;
    }

    .level-up-card-right-content img {
        position: absolute;
        top: 300px;
        z-index: 0;
    }
}
