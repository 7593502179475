/* STYLES FOR CALL TO ACTION */
.cta-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px !important;
    background: radial-gradient(circle at center,
        rgba(141, 42, 255, 0.6) 0%,
        rgba(18, 9, 29, 1) 60%,
        rgba(18, 9, 29, 1) 100%);
    text-align: center;
    padding: 126px 149px;
    backdrop-filter: blur(100px);
  }
  
  .cta-container h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 130% !important;
    letter-spacing: 0 !important;
    color: white !important;
    z-index: 1000;
  }

  .cta-container h6 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    letter-spacing: 0 !important;
    max-width: 850px;
  }
  
  .cta-container button {
    border: 1px solid white !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: white !important;
  }

  @media (max-width: 768px) {
    .cta-container {
      padding: 100px 16px;
    }

    .cta-container button {
      width: 100%;
    }
  }